/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.12.5-v202503262149-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqConditionsApiClass {
 
   constructor() {}

  /**
   * @summary Add capsules to a stored condition
   * @param {string} id - ID of the stored condition that will receive the capsules
   */
  public addCapsules(
    body: models.CapsulesInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/conditions/${encodeURIComponent(String(id))}/capsules`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Archive a condition and its capsules
   * @param {string} id - ID of the condition to archive
   */
  public archiveCondition(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/conditions/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ArchiveOutputV1>;
  }

  /**
   * @summary Create a condition
   */
  public createCondition(
    body: models.ConditionInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/conditions`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ConditionOutputV1>;
  }

  /**
   * @summary Delete all capsules in a condition
   * @param {string} id - The Seeq ID for the signal
   * @param {boolean} [clearCapsuleProperties=false] - When true, clears capsule property definitions from the condition after deleting the capsules.
   */
  public deleteCapsules(
    {
      id,
      clearCapsuleProperties
    } : {
      id: string,
      clearCapsuleProperties?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/conditions/${encodeURIComponent(String(id))}/capsules`,

      params: omitBy({
        ['clearCapsuleProperties']: clearCapsuleProperties
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Get capsules from a condition
   * @param {string} id - ID of the condition from which to retrieve capsules
   * @param {string} start - A string representing the start of the range of the capsules to be returned. The contents vary based on the condition type. For time conditions: a ISO 8601 timestamp (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm).
   * @param {string} end - A string representing the end of the range of the capsules to be returned. The contents vary based on the condition type. For time conditions: a ISO 8601 timestamp (YYYY-MM-DDThh:mm:ss.sssssssss±hh:mm).
   * @param {string} [unitOfMeasure] - The unit of measure in which to return the condition&#x27;s capsules&#x27; starts and ends. If none is supplied, the data is returned in the condition&#x27;s default unit of measure.
   * @param {string} [timeFormat=ISO8601] - The timestamp format.
   * @param {number} [limit=1000] - The pagination limit, the total number of collection items that will be returned in this page of results
   * @param {string} [continuationToken] - An opaque token used to query for the next page of results. Only use if returned by a previous call.
   */
  public getCapsules(
    {
      id,
      start,
      end,
      unitOfMeasure,
      timeFormat,
      limit,
      continuationToken
    } : {
      id: string,
      start: string,
      end: string,
      unitOfMeasure?: string,
      timeFormat?: TimeFormatEnum,
      limit?: number,
      continuationToken?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(start)) {
      throw new Error("'start' parameter required");
    }

    if (isNil(end)) {
      throw new Error("'end' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/conditions/${encodeURIComponent(String(id))}/capsules`,

      params: omitBy({
        ['start']: start,
        ['end']: end,
        ['unitOfMeasure']: unitOfMeasure,
        ['timeFormat']: timeFormat,
        ['limit']: limit,
        ['continuationToken']: continuationToken
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.CapsulesOutputV1>;
  }

  /**
   * @summary Get a condition
   * @param {string} id - ID of the condition to retrieve
   */
  public getCondition(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/conditions/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ConditionOutputV1>;
  }

  /**
   * @summary Get a collection of conditions
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getConditions(
    {
      offset,
      limit
    } : {
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/conditions`,

      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemPreviewListV1>;
  }

  /**
   * @summary Replace any capsules in the same time range
   * @param {string} id - ID of the stored condition that will receive the capsules
   */
  public putCapsules(
    body: models.CapsulesOverwriteInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'put',
      url: APPSERVER_API_PREFIX + `/conditions/${encodeURIComponent(String(id))}/capsules`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Create or update multiple conditions
   */
  public putConditions(
    body?: models.ConditionBatchInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/conditions/batch`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemBatchOutputV1>;
  }

  /**
   * @summary Update an existing condition
   * @param {string} id - The id for the condition to be updated
   */
  public updateExistingCondition(
    body: models.ConditionUpdateInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/conditions/${encodeURIComponent(String(id))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ConditionOutputV1>;
  }

}

export enum TimeFormatEnum {
    Legacy = 'Legacy' as any,
    ISO8601 = 'ISO8601' as any,
    Nanoseconds = 'Nanoseconds' as any
}

export const sqConditionsApi = new sqConditionsApiClass();
